<template>
    AutoFind
    <button type="button" class="btn btn-success" @click="getOntAutoFind">Odswież</button><br>
    <div v-if="isLoading">Pobieranie...</div>
    <div v-if="autofind">
    <div class="card mt-4">
        <div class="card-header">
            AutoFind
        </div>
        <div class="card-body">
            <table class="table table-bordered mt-4">
                <thead>
                    <tr>
                        <th>Port</th>
                        <th>SN/MAC</th>
                        <th>Model</th>
                        <th>Data</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(ont, index) in autofind" :key="index">
                        <td>{{ ont.Frame }}/{{ ont.Slot }}/{{ ont.Port }}</td>
                        <td>{{ ont.SerialNumberOrMac }}</td>
                        <td>{{ ont.Model }}</td>
                        <td>{{ ont.FindTime }}</td>
                        <td @click="addOntModal(ont.Frame, ont.Slot, ont.Port, ont.SerialNumberOrMac)">+</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<Modal v-show="isModalVisible" @close="closeModal">
    <template v-slot:body>
        <form @submit.prevent="addOnt">
            <div class="form-floating mb-3">
                <input class="form-control" id="serialOrMac" type="text" placeholder="serialOrMac" v-model="modalDataSerialOrMac" disabled/>
                <label for="serialOrMac">SN/MAC</label>
            </div>
            <div class="form-floating mb-3">
                <select class="form-select" id="servicePortTemplate" aria-label="Service Port" v-model="modalDataServicePortTemplateId" required>
                    <option v-for="otl in oltTemplateServicePortList" v-bind:key="otl.Id" :value="otl.Id">{{ otl.Name }}</option>
                </select>
                <label for="lineProfile">Service Port</label>
            </div>
            <div class="form-floating mb-3">
                <input class="form-control" id="description" type="text" placeholder="Opis" v-model="modalDataDescription" required/>
                <label for="description">Opis</label>
            </div>
            <div class="d-grid">
                <button class="btn btn-primary btn-lg" id="submitButton" type="submit">Zapisz</button>
            </div>
        </form>
    </template>
</Modal>

</template>

<script>
import axios from 'axios'
import Modal from '../components/Modal.vue'

export default {
    components: {
        Modal
    },
    data() {
        return {
            autofind: null,
            isLoading: false,
            isModalVisible: false,
            modalDataSlot: null,
            modalDataFrame: null,
            modalDataPort: null,
            modalDataSerialOrMac: null,
            modalDataServicePortTemplateId: null,
            modalDataDescription: null,
            oltTemplateServicePortList: null,
        };
    },
    methods: {
        getOntAutoFind() {
            this.isLoading = true
            const sessionId = localStorage.getItem("user")
            const data = {
                SessionId: sessionId,
                HuaweiGetOntAutoFind: {
                OltId: parseInt(this.$route.params.id),
                },
            }
            axios.post("/huawei/olt/getontautofind", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.autofind = result.data.HuaweiGetOntAutoFind;
                this.isLoading = false
            })
        },
        addOntModal(frame, slot, port, serialOrMac) {
            this.modalDataFrame = frame
            this.modalDataSlot = slot
            this.modalDataPort = port
            this.modalDataSerialOrMac = serialOrMac

            this.oltTemplateServicePortList = null
            var data = {
                SessionId: localStorage.getItem('user'),
                HuaweiGetTemplateServicePortList: {
                    OltId: parseInt(this.$route.params.id)
                }
            };
            axios.post("/huawei/olt/template/serviceport/list", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.oltTemplateServicePortList = result.data.HuaweiGetTemplateServicePortList
            })

            this.isModalVisible = true
        },
        addOnt()
        {
            const { modalDataSerialOrMac, modalDataServicePortTemplateId, modalDataDescription } = this
            const data = {
                SessionId: localStorage.getItem('user'),
                HuaweiAddOnt: {
                    OltId: parseInt(this.$route.params.id),
                    Frame: parseInt(this.modalDataFrame),
                    Slot: parseInt(this.modalDataSlot),
                    Port: parseInt(this.modalDataPort),
                    SerialOrMac: modalDataSerialOrMac,
                    ServicePortTemplateId: parseInt(modalDataServicePortTemplateId),
                    Description: modalDataDescription
                }
            }

            axios.post("/huawei/olt/addont", data).then((result) => {
                if (this.isset(() => result.data.ErrorId) && result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                if (result.data.HuaweiAddOnt.Error)
                    this.$store.dispatch('alert/error', result.data.HuaweiAddOnt.Error, { root: true })

                this.$router.push('/huawei/ont/' + result.data.HuaweiAddOnt.OntId)
            })

            this.isModalVisible = false
        },
        closeModal() {
            this.isModalVisible = false
        }
    },
}
</script>
