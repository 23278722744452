<template>
<OltPorts />
<div class="mt-4">
    <AutoFind />
</div>
<div class="card mt-4">
    <div class="card-header">
        List ONT
    </div>
    <div class="card-body">
        <DataTable :value="Onts" :paginator="true" :rows="10" class="p-datatable-sm"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :rowsPerPageOptions="[10,20,50]" responsiveLayout="scroll"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" v-model:filters="filters"
            :globalFilterFields="[ 'SerialOrMac', 'Description']">
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <span class="p-input-icon-left">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Szukaj" />
                        </span>
                    </div>
                </template>
            <Column field="Frame" header="Port">
                <template #body="ont">
                    {{ ont.data.Frame }}/{{ ont.data.Slot }}/{{ ont.data.Port }}
                </template>
            </Column>
            <Column field="OntId" header="Ont Id"></Column>
            <Column field="SerialOrMac" header="SN/MAC">
                <template #body="ont">
                    <router-link :to="`/huawei/ont/${ont.data.Id}`">{{ ont.data.SerialOrMac }}</router-link>
                </template>
            </Column>
            <Column field="Description" header="Opis"></Column>
            <Column field="Status" header="Status"></Column>
        </DataTable>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import Column from 'primevue/column/Column'
import DataTable from 'primevue/datatable/DataTable'
import InputText from 'primevue/inputtext/InputText'
import {FilterMatchMode} from 'primevue/api';
//import OltPorts from '../../components/OltPorts.vue'
import AutoFind from '../../components/AutoFind.vue'
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css'
import 'primevue/resources/primevue.min.css'

export default {
    components: {
        //OltPorts,
        AutoFind,
        DataTable,
        Column,
        InputText
    },
    data() {
        return {
            Onts: [],
            filters: {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
            }
        }
    },
    mounted() {
        this.GetOntList()
    },
    methods: {
        GetOntList()
        {
            const data = {
                SessionId: localStorage.getItem("user"),
                HuaweiOltListOnt: {
                    OltId: parseInt(this.$route.params.id)
                },
            }

            axios.post("/huawei/olt/ont/list", data).then((result) => {
                if (result.data.ErrorId == 401)
                {
                    this.$store.dispatch('alert/error', result.data.Error, { root: true })
                    this.$store.dispatch('auth/logout')
                }

                this.Onts = result.data.HuaweiOltListOnt
            })
        },
    }
};
</script>
